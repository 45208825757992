import classNames from 'classnames';
import { Box, useMedia } from '@stitch-fix/mode-react';
import { useEffect, useState, useRef } from 'react';
import { CloudinaryImageFields } from '../CloudinaryImageWrapper';
import { MediaItem } from './MediaItem';
import styles from './styles.module.scss';
import { ContentItem } from './ContentItem';
import { useStickyCtaContext } from '../StickyCta/useStickyCtaContext';
import { ModularComponentProps } from '../ModularPageWrapper/ModularPageWrapper';

type GalleryHeroItem = {
  minorTitle: string;
  majorTitle: string;
  cta: {
    text: string;
    url: string;
  };
  media?: {
    mobile: CloudinaryImageFields;
    desktop: CloudinaryImageFields;
  };
  CtaButton?: React.ReactNode;
};

export type GalleryHeroProps = {
  items: GalleryHeroItem[];
  layout?: 'full' | 'split';
  buttonColor?: string;
};

const SLIDE_DURATION = 2500;

export const GalleryHero = ({
  layout = 'full',
  items,
  buttonColor,
  verticalSpacing,
}: GalleryHeroProps & ModularComponentProps) => {
  const prefersReducedMotion = useMedia('(prefers-reduced-motion)');
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { setStickyContainerTarget } = useStickyCtaContext();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const requestAnimationId = useRef<number>(0);

  useEffect(() => {
    const handleSlideAutoAdvance = () => {
      let start = performance.now();

      const tick = (timestamp: number) => {
        if (timestamp - start >= SLIDE_DURATION) {
          setShouldAnimate(true);
          setActiveSlideIndex(
            prevSlideIndex => (prevSlideIndex + 1) % items.length,
          );
          start = timestamp;
        }
        requestAnimationId.current = requestAnimationFrame(tick);
      };

      requestAnimationId.current = requestAnimationFrame(tick);
    };

    if (!prefersReducedMotion) {
      handleSlideAutoAdvance();
    }

    return () => cancelAnimationFrame(requestAnimationId.current);
  }, [items.length, prefersReducedMotion]);

  return (
    <Box bgColor="gray-99" mt={verticalSpacing}>
      <Box
        ref={setStickyContainerTarget}
        className={classNames(styles.container, {
          [styles.splitView]: layout === 'split',
        })}
      >
        <Box className={styles.media}>
          {items.map((item, index) => (
            <MediaItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              slideIndex={index}
              activeSlideIndex={activeSlideIndex}
              items={items}
              initialAnimate={shouldAnimate}
            />
          ))}
        </Box>
        <Box className={styles.content}>
          <ContentItem
            slideIndex={activeSlideIndex}
            items={items}
            buttonColor={buttonColor}
          />
        </Box>
      </Box>
    </Box>
  );
};
