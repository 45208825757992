import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useMeasure } from 'react-use';
import classNames from 'classnames';
import type {
  CloudinaryContext,
  SourceOptions,
} from 'cloudinary-video-player/types/video-player';
import {
  Box,
  COLORS,
  HiddenFromView,
  IconButton,
  IconPause32 as Pause,
  IconPlay32 as Play,
  IconPause16 as PauseSmall,
  IconPlay16 as PlaySmall,
  useMedia,
} from '@stitch-fix/mode-react';
import { useInView } from 'react-intersection-observer';
import { getFileName, getVideoPoster } from './utils';
import styles from './styles.module.scss';
import { useLoadPlayerScript } from './useLoadPlayerScript';

type UsePlayerProps = Pick<
  CloudinaryVideoPlayerProps,
  | 'videoUrl'
  | 'soundControls'
  | 'captions'
  | 'playPauseOnScroll'
  | 'allowAutoPlay'
  | 'muted'
> & { videoWidth: number; inView: boolean };

const usePlayer = ({
  inView,
  videoUrl,
  soundControls = false,
  allowAutoPlay = true,
  captions,
  muted = true,
  playPauseOnScroll = false,
  videoWidth,
}: UsePlayerProps) => {
  const playerRef = useRef<CloudinaryContext['player']>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const prefersReducedMotion = useMedia('(prefers-reduced-motion)');
  const [isPaused, setIsPaused] = useState(
    !allowAutoPlay || prefersReducedMotion,
  );
  const [isInitialized, setIsInitialized] = useState(false);
  const { isPlayerScriptLoaded, onScriptReady } = useLoadPlayerScript();
  const videoId = getFileName(videoUrl);

  useEffect(() => {
    // if the cloudinary player script hasn't loaded yet return early
    if (!isPlayerScriptLoaded) {
      return;
    }

    if (!isInitialized) {
      playerRef.current = window.cloudinary.videoPlayer(videoId, {
        cloud_name: 'stitch-fix',
        // hide default cloudinary logo
        showLogo: false,
        // hide menu that appears when right clicking on the video
        hideContextMenu: true,
        // hide the default play button that appears in the middle of the video
        bigPlayButton: false,
        // only show controls if soundControls prop is passed. Otherwise we show
        // a custom play pause bottom
        controls: soundControls,
        // only autoplay the video if the user preferences does not have
        // prefers-reduced-motion set
        autoplay: allowAutoPlay && !prefersReducedMotion && !playPauseOnScroll,
        videojs: {
          muted,
          html5: {
            // set native tracks to false because they are being cut-off in iOS
            // do to CSS overflow hidden
            nativeTextTracks: false,
          },
        },
        // customize the players colors
        colors: {
          base: COLORS['gray-16'],
          accent: COLORS.white,
          text: COLORS.white,
        },
      });

      setIsInitialized(true);

      return;
    }

    if (inView) {
      // once the player has been initialized we can set the source file
      playerRef.current?.source(
        // cloudinary public_id of the video is everything proceeding /upload/ in the url
        videoUrl.split('/upload/')[1],
        {
          // if a captions url exists set the textTracks to show captions
          ...(captions?.url && {
            textTracks: {
              captions,
            },
          }),
          // apply custom transformations to the video to reduce consumed bandwidth
          transformation: [
            {
              // perform automatic format and codec selection based on the requesting browser
              format: 'auto',
              // performs automatic quality encoding resulting in a relatively small file size with good visual quality
              quality: 'auto',
              // apply a static width based on alloted size in layout
              width: videoWidth,
              // This mode doesn't scale up the video if your requested dimensions are larger than the original video's.
              crop: 'limit',
            },
          ],
        } as SourceOptions,
      );
    }
  }, [
    inView,
    allowAutoPlay,
    isPlayerScriptLoaded,
    captions,
    isInitialized,
    muted,
    prefersReducedMotion,
    soundControls,
    videoId,
    videoUrl,
    videoWidth,
    playPauseOnScroll,
  ]);

  const toggleVideoPause = () => {
    if (playerRef.current === undefined) {
      return;
    }

    if (isPaused) {
      playerRef.current.play();
      setIsPaused(false);
    } else {
      playerRef.current.pause();
      setIsPaused(true);
    }
  };

  return {
    videoRef,
    videoId,
    toggleVideoPause,
    isPaused,
    prefersReducedMotion,
    onScriptReady,
    playerRef,
  };
};

export type CloudinaryVideoPlayerProps = {
  videoUrl: string;
  captions?: {
    label: string;
    url?: string;
    language?: 'en-US';
    default: boolean;
  };
  videoDescription?: string;
  soundControls?: boolean;
  playPauseButtonSize?: 'small' | 'large' | 'none';
  playPauseButtonAlignment?: 'left' | 'right';
  playPauseOnScroll?: boolean;
  aspectRatio?: string;
  allowAutoPlay?: boolean;
  muted?: boolean;
};

/**
 * get width of video based upon measure
 * round at a decent interval to avoid cloudinary transformation fees
 * round down to rely on upscaling
 */
const videoWidth = (width: number) => {
  const interval = 100;

  return Math.floor(width / interval) * interval;
};

export type CloudinaryVideoPlayerHandle = {
  play: () => void;
  pause: () => void;
  restart: () => void;
};

export const CloudinaryVideoPlayer = forwardRef<
  CloudinaryVideoPlayerHandle,
  CloudinaryVideoPlayerProps
>(
  (
    {
      videoUrl,
      videoDescription,
      soundControls,
      captions,
      playPauseButtonSize = 'large',
      playPauseButtonAlignment = 'right',
      playPauseOnScroll,
      aspectRatio = '',
      allowAutoPlay = true,
      muted = true,
    },
    ref,
  ) => {
    const [inViewRef, inView] = useInView({ threshold: 0 });
    const [videoPlayerContainer, { width }] = useMeasure<HTMLDivElement>();
    const roundedWidth = useMemo(() => videoWidth(width), [width]);
    const { poster } = useMemo(
      () => ({ poster: getVideoPoster(videoUrl, roundedWidth) }),
      [videoUrl, roundedWidth],
    );
    const {
      videoRef,
      videoId,
      toggleVideoPause,
      isPaused,
      prefersReducedMotion,
      onScriptReady,
      playerRef,
    } = usePlayer({
      inView,
      videoUrl,
      soundControls,
      captions,
      playPauseOnScroll,
      videoWidth: roundedWidth,
      allowAutoPlay,
      muted,
    });

    const playIcon = playPauseButtonSize === 'small' ? <PlaySmall /> : <Play />;
    const pauseIcon =
      playPauseButtonSize === 'small' ? <PauseSmall /> : <Pause />;

    useImperativeHandle(ref, () => ({
      play: () => {
        toggleVideoPause();
      },
      pause: () => {
        playerRef.current?.pause();
      },
      restart: () => {
        playerRef.current?.pause();
        playerRef.current?.currentTime(0);
        setTimeout(() => {
          playerRef.current?.play();
        });
      },
    }));

    return (
      <>
        <Head>
          <link
            href="https://unpkg.com/cloudinary-video-player@1.9.9/dist/cld-video-player.min.css"
            rel="stylesheet"
          />
        </Head>
        <Script
          id="cloudinary-video-script"
          type="text/javascript"
          src="https://unpkg.com/cloudinary-video-player@1.9.0/dist/cld-video-player.light.min.js"
          onLoad={() => {
            onScriptReady();
          }}
          onReady={() => {
            onScriptReady();
          }}
        />
        <Box
          className={styles.container}
          aria-hidden={!videoDescription}
          ref={(e: HTMLDivElement) => {
            inViewRef(e);
            videoPlayerContainer(e);
          }}
        >
          <video
            data-testid="video"
            ref={videoRef}
            poster={poster}
            id={videoId}
            controls={soundControls}
            autoPlay={
              allowAutoPlay && !prefersReducedMotion && !playPauseOnScroll
            }
            aria-describedby={videoDescription && `${videoId}_description`}
            className={styles.video}
            muted
            loop
            playsInline
            style={{ aspectRatio }}
          />
          {videoDescription && (
            <HiddenFromView>
              <div id={`${videoId}_description`}>{videoDescription}</div>
            </HiddenFromView>
          )}
          {!soundControls && playPauseButtonSize !== 'none' && (
            <Box
              onClick={toggleVideoPause}
              className={styles.playPauseButtonContainer}
            >
              <Box
                className={classNames(styles.playPauseButton, {
                  [styles.leftAlign]: playPauseButtonAlignment === 'left',
                })}
              >
                <IconButton
                  variant="knockout"
                  aria-label={isPaused ? 'Play video' : 'Pause video'}
                  onClick={toggleVideoPause}
                >
                  {isPaused ? playIcon : pauseIcon}
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  },
);
