import React from 'react';
import {
  Box,
  Carousel,
  ResponsiveSection,
  type CloudinaryImageProps,
  type CarouselProps,
} from '@stitch-fix/mode-react';
import classNames from 'classnames';
import {
  ModuleWrapper,
  type ModuleWrapperProps,
} from '../ModuleWrapper/ModuleWrapper';
import { ImageSlide, type ImageSlideData } from './ImageSlide';
import { VideoSlide, type VideoSlideData } from './VideoSlide';
import styles from './style.module.scss';
import { SizeConfig } from '../CSCloudinaryAsset/utils';
import { MODE_MIN_WIDTH } from '../../utils/values/mediaQueries';

const getWidth = (width: string): CarouselProps['slideWidth'] => {
  switch (width) {
    case 'large':
      return {
        sm: '80%',
        md: '384px',
        lg: '384px',
        xl: '496px',
        xxl: '496px',
      };
    case 'medium':
      return {
        sm: '60%',
        md: '272px',
        lg: '272px',
        xl: '352px',
        xxl: '352px',
      };
    case 'small':
    default:
      return {
        sm: '40%',
        md: '224px',
        lg: '224px',
        xl: '280px',
        xxl: '280px',
      };
  }
};

const getImageSources = (width: string): CloudinaryImageProps['sources'] => {
  switch (width) {
    case 'large':
      return {
        sm: { w: 435 },
        md: { w: 530 },
        lg: { w: 388 },
        xl: { w: 399 },
      };
    case 'medium':
      return {
        sm: { w: 326 },
        md: { w: 354 },
        lg: { w: 277 },
        xl: { w: 342 },
      };
    case 'small':
    default:
      return {
        sm: { w: 218 },
        md: { w: 222 },
        xl: { w: 274 },
      };
  }
};

export type SlideData = ImageSlideData | VideoSlideData;

const isImageSlide = (slide: SlideData): slide is ImageSlideData =>
  'image' in slide;

export type InteractiveCarouselProps = {
  slideWidth: 'small' | 'medium' | 'large';
  slides: SlideData[];
} & Omit<ModuleWrapperProps, 'children'>;

const sizes = new Map<InteractiveCarouselProps['slideWidth'], SizeConfig[]>([
  [
    'small',
    [
      { minWidth: 0, fluidWidth: 35 },
      { minWidth: MODE_MIN_WIDTH.md, staticWidth: 250 },
    ],
  ],
  [
    'medium',
    [
      { minWidth: 0, fluidWidth: 55 },
      { minWidth: MODE_MIN_WIDTH.md, staticWidth: 350 },
    ],
  ],
  [
    'large',
    [
      { minWidth: 0, fluidWidth: 75 },
      { minWidth: MODE_MIN_WIDTH.md, staticWidth: 530 },
      { minWidth: MODE_MIN_WIDTH.lg, staticWidth: 390 },
    ],
  ],
]);

export const InteractiveCarousel = ({
  moduleTitle,
  alignment,
  moduleFooter,
  slideWidth,
  slides,
}: InteractiveCarouselProps) => {
  return (
    <ResponsiveSection width="xxlarge" data-testid="InteractiveCarousel">
      <ModuleWrapper
        alignment={alignment}
        moduleTitle={moduleTitle}
        moduleFooter={moduleFooter}
      >
        <Box
          className={classNames(styles.carouselContainer, {
            [styles.leftAlign]: alignment === 'left',
            [styles.centerAlign]: alignment === 'center',
          })}
        >
          <Carousel
            ariaLabel="Interactive Carousel"
            slideWidth={getWidth(slideWidth)}
          >
            {slides.map((slide, i) => {
              if (isImageSlide(slide)) {
                return (
                  <ImageSlide
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${slide.image.src}-${i}`}
                    label={slide.label}
                    href={slide.href}
                    image={{
                      ...slide.image,
                      sources: getImageSources(slideWidth),
                    }}
                    sizes={sizes.get(slideWidth)}
                  />
                );
              }

              return (
                <VideoSlide
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${slide.video.videoUrl}-${i}`}
                  label={slide.label}
                  href={slide.href}
                  video={{ ...slide.video }}
                />
              );
            })}
          </Carousel>
        </Box>
      </ModuleWrapper>
    </ResponsiveSection>
  );
};
